<template>
  <div :id="id" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[id]"
      @ok="ok" @hidden="hidden">

      <AlertFeedback v-if="alertMsg != null" :msg="alertMsg" :details="alertMsgDetails.list" :detailTitle="alertMsgDetails.title" :alertState="alertState" @resetAlert="resetAlert"/>

      <ListFilter :termOnly="true" :term="searchFilterTerm" @applyFilter="applyFilter"/>

      <div class="grid-toolbar border" v-if="allowManage">
        <template v-if="canAdd()">
          <span :id="`BTN_ADD_${id}`">
            <b-btn @click="modalOpen(true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
          </span>
          <b-popover :target="`BTN_ADD_${id}`" triggers="hover" placement="top">
            {{ $t('button.add') }}
          </b-popover>
        </template>
        <template v-if="canView()">
          <span :id="`BTN_EDIT_${id}`">
            <b-btn :disabled="disableEdit" @click="modalOpen(false)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
          </span>
          <b-popover :target="`BTN_EDIT_${id}`" triggers="hover" placement="top">
            {{ $t('button.edit') }}
          </b-popover>
        </template>
        <template v-if="canAdd()">
          <span :id="`BTN_DUPLICATE_${id}`">
            <b-btn :disabled="disableDuplicate" @click="showDuplicateDialog"><font-awesome-icon :icon="['far','clone']"/></b-btn>  
          </span>
          <b-popover :target="`BTN_DUPLICATE_${id}`" triggers="hover" placement="top">
            {{ $t('button.duplicate') }}
          </b-popover>
        </template>
        <template v-if="canDelete()">
          <span :id="`BTN_DELETE_${id}`">
            <b-btn :disabled="disableDelete" @click="rowDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
          </span>
          <b-popover :target="`BTN_DELETE_${id}`" triggers="hover" placement="top">
            {{ $t('button.delete') }}
          </b-popover>
        </template>
        <span :id="`BTN_IMPORT_DOCUMENT_${id}`">
          <b-btn @click="fileImport"><font-awesome-icon :icon="['far', 'inbox-in']"/></b-btn>
        </span>
        <b-popover :target="`BTN_IMPORT_DOCUMENT_${id}`" triggers="hover" placement="top">
          {{ $t('skill.button.import_document') }}
        </b-popover>
        <span :id="`BTN_EXPORT_DOCUMENT_${id}`">
          <b-btn @click="fileExport"><font-awesome-icon :icon="['far', 'inbox-out']"/></b-btn>
        </span>
        <b-popover :target="`BTN_EXPORT_DOCUMENT_${id}`" triggers="hover" placement="top">
          {{ $t('skill.button.export_document') }}
        </b-popover>
        <span @[colorMouseEnterEvent]="onColoringOver" @mouseleave="onColoringLeave">
          <b-dropdown :id="`BTN_COLORING_${id}`" ref="coloring" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far', 'palette']"/>
            </template>
            <b-dropdown-group :header="$t('colorby')">
              <b-dropdown-item @click="onColorChange('none')" href="#">
                <span class="action-item-label">{{ $t('none') }}</span><font-awesome-icon class="active-check" v-if="coloring.none" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('skill')" href="#">
                <span class="action-item-label">{{ $t('skill.coloring.skill') }}</span><font-awesome-icon class="active-check" v-if="coloring.skill" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </span>
      </div>

      <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height" id="skill-grid"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            :columnDefs="columnDefs"
            :context="context"
            :defaultColDef="defaultColDef"
            :getRowId="params => params.data.uuId"
            noRowsOverlayComponent="noRowsOverlay"
            :noRowsOverlayComponentParams="noRowsOverlayComponentParams"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            pagination
            :paginationPageSize="1000"
            :cacheBlockSize="10000"
            :rowData="rowData"
            rowModelType="serverSide"
            :rowSelection="multiple? 'multiple':'single'"
            rowMultiSelectWithClick
            :serverSideInfiniteScroll="true"
            :sideBar="false"
            suppressDragLeaveHidesColumns
            suppressCellFocus
            suppressContextMenu
            suppressMultiSort
            >
     </ag-grid-vue>

      <template v-slot:modal-footer="{ ok, cancel }">
        <template v-if="allowSelect">
          <b-button :disabled="disableOk && !allowNone" size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('MANAGE' === mode?'button.close':'button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <SkillModal v-if="skillShow" :id="skillId" :show.sync="skillShow" @success="modalSuccess" :title="skillTitle"/>

    <b-modal :title="duplicateTitle"
        v-model="duplicateShow"
        @hidden="duplicateCancel"
        content-class="shadow"
        no-close-on-backdrop
        >
      <b-form-group :label="$t('skill.field.name')" label-for="name">
        <b-input-group>
          <b-form-input id="name" type="text"
            :data-vv-as="$t('skill.field.name')"
            data-vv-name="duplicate.name"
            data-vv-delay="500"
            trim
            v-model="duplicateName"/>
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateNameError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicate.name') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
          <b-button v-if="duplicateInProgress" disabled size="sm" variant="success"><b-spinner small type="grow" />{{ $t('button.processing') }}</b-button>
          <b-button v-else size="sm" variant="success" @click="duplicateOk">{{ $t('button.duplicate') }}</b-button>
          <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal title="Confirm Deletion"
        v-model="confirmDeleteShow"
        @ok="confirmDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t(selected.length > 1? 'skill.confirmation.delete_plural':'skill.confirmation.delete') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <!--Gantt Import Dialog -->
    <GanttImportDialog 
      :properties="docImportProperties" 
      :mode="'SKILL'" :show="docImportShow"
      :existingData="existingData"
      :title="$t('skill.button.import_document')"
      @modal-ok="docImportOk"
      @modal-cancel="docImportCancel" />
    
    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable"/>
  </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import alertStateEnum from '@/enums/alert-state';
import { filterOutViewDenyProperties, columnDefSortFunc } from '@/views/management/script/common'
import { strRandom, addTags, invertColor } from '@/helpers';
import { skillService, viewProfileService, skillLinkTagService } from '@/services';
import { fieldValidateUtil } from '@/script/helper-field-validate';

import ListFilter from '@/components/ListFilter/ListFilter';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import ColorCellRenderer from '@/components/Aggrid/CellRenderer/Color';
import NoRowsOverlay from '@/components/Aggrid/Overlay/NoRows';
import SkillLevelCellRenderer from '@/components/Aggrid/CellRenderer/SkillLevel';
import GenericCellRenderer from '@/components/Aggrid/CellRenderer/Generic';

function ServerSideDatasource(self) {
  return {
    getRows(params) {
      const companyUuId = self.$store.state.company && self.$store.state.company.type !== 'Primary' ? self.$store.state.company.uuId : null;
      skillService.list(self.buildParams(params), companyUuId, { enableLevelGroup: true, levelList: self.skillLevelOpts}).then((response) => {
        self.totalRecords = response.arg_total;
        params.successCallback(response.data, response.arg_total);
        if(params.api && self.modalShow) {
          params.api.deselectAll();
          if(self.selected && self.selected.length > 0) {
            const selected = cloneDeep(self.selected);
            //Wait 300ms to let the aggrid internal data to be ready, before calling getRowNode().
            setTimeout(() => {
              for (const id of selected) {
                const rowNode = params.api.getRowNode(id);
                if (rowNode != null) {
                  rowNode.setSelected(true);
                } else {
                  continue;
                }
              }
            }, 300);
          }
        }
        params.api.hideOverlay();
        if (response.arg_total === 0) {
          self.showNoRowLabel(null)
        }
      })
      .catch(e => {
        params.successCallback([], 0);
        if (e != null && e.response != null && e.response.status == 403) {
          self.showNoRowLabel(self.$t('entity_selector.error.insufficient_permission_to_show_data'))
        }
      });
    }
  }
}

export default {
  name: 'SkillSelectorModal',
  components: {
    'ag-grid-vue': AgGridVue,
    SkillModal: () => import('@/components/modal/SkillModal'),
    GanttImportDialog: () => import('@/components/Gantt/components/GanttImportDialog'),
    ListFilter,
    AlertFeedback: () => import('@/components/AlertFeedback'),
    InProgressModal: () => import('@/components/modal/InProgressModal'),
    /* eslint-disable vue/no-unused-components */
    'detailLinkCellRenderer': DetailLinkCellRenderer,
    'colorCellRenderer': ColorCellRenderer,
    genericCellRenderer: GenericCellRenderer,
    skillLevelCellRenderer: SkillLevelCellRenderer
    //Overlay
    , noRowsOverlay: NoRowsOverlay
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    },
    title: {
      type: String,
      default: null
    },
    selectedSkill: {
      type: Array,
      default: () => { return []; }
    },
    allowNone: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      id: `SKILL_LIST_${strRandom(5)}`,
      permissionName: 'SKILL',
      inProgressShow: false,
      inProgressLabel: null,
      inProgressStoppable: false,
      inProgressState: {
        cancel: false
      },
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: [],
      context: null,
      defaultColDef: null,
      rowData: null,
  
      modalShow: false,
      disableEdit: true,
      disableDuplicate: true,
      disableDelete: true,
      disableOk: true,
      selected: [],

      skillId: null,
      skillShow: false,
      alertMsg: null,
      alertMsgDetails: { title: null, list: [] },
      alertState: alertStateEnum.SUCCESS,

      confirmDeleteShow: false,
      totalRecords: 0,
      
      searchFilter: '',
      searchFilterTerm: '',
      
      duplicateShow: false,
      duplicateName: null,
      duplicateInProgress: false,
      
      docImportShow: false,
      existingData: null,
      docImportProperties: [],
      
      coloring: {
        none: true,
        skill: false
      },
      lastOpenColumnMenuParams: null,
      noRowsMessage: null,
      noRowsOverlayComponentParams: null,

      profileColumnLoaded: false
    };
  },
  beforeMount() {
    this.userId = this.$store.state.authentication.user.uuId;
    const profileKey = 'skill_selector_list';
    const getColumnDefs = (c) => {
      return {
        colId: c.colId
        , width: c.actualWidth
        , sort: c.sort != null? c.sort : null
        , sortIndex: c.sortIndex != null? c.sortIndex : null
      }
    }

    const self = this;
    this.gridOptions = {
      onSelectionChanged: function(event) {
        self.selected.splice(0, self.selected.length, ...(event.api.getSelectedNodes().map(i => i.data.uuId)));
        self.disableEdit = self.disableDuplicate = self.selected.length != 1;
        self.disableDelete = self.selected.length < 1;
        self.disableOk = (self.multiple? (self.selected.length < 1): (self.selected.length != 1));
      },
      onColumnVisible: function(params) {
        if (!self.profileColumnLoaded) {
          return
        }
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.columnApi
            .getAllGridColumns()
            .findIndex(col => {
              return col === self.lastOpenColumnMenuParams.column;
            });

          params.columnApi.moveColumn(colKey, columnMenuColumnIndex + 1);
        }
        const cols = params.columnApi.getAllGridColumns().map(i => { 
          return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
        const columnState =  params.columnApi.getColumnState();
        //get the actual hide value from columnState
        for (const col of columnState) {
          const found = cols.find(i => i.colId == col.colId)
          if (found) {
            found.hide = col.hide;
          }
        }
        cols.sort(columnDefSortFunc)
        for (const [index,c] of cols.entries()) {
          params.columnApi.moveColumn(c.colId, index);
        }

        const columns = params.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      },
      onSortChanged: function(event) {
        const columns = event.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onDragStopped: function(event) {
        const columns = event.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onFirstDataRendered: function(event) {
        if (self.newToProfile != null && self.newToProfile == true) {
          self.newToProfile = null;
          self.gridApi.sizeColumnsToFit();
          self.$nextTick(() => {
            const columns = event.columnApi.getAllDisplayedColumns();
            self.settings[profileKey] = columns.map(c => getColumnDefs(c));
            self.updateViewProfile();
          })
        }
      }
    };
    
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      lockPinned: true,
      hide: true,
      menuTabs: ['columnsMenuTab']
    };

    this.context = {
      componentParent: self
    };
  },
  mounted() {
    this.init()
  },
  created() {
    this.skillLevelOpts = []
    this.skillLevelNames = []
    this.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage
    }
    this.updateModalShow(this.show);
    this.selected = this.selectedSkill;
  },
  beforeDestroy() {
    this.userId = null;
    this.newToProfile = null;
    this.skillLevelOpts = null;
    this.skillLevelNames = null;
  },
  watch: {
    async show(newValue) {
      if(newValue) {
        this.resetAlert();
        this.searchFilter = "";
        this.profileColumnLoaded = false;
        this.init()
      } else {
        //When modal is hidden/closed, grid is destroyed. All the references become obsolete and should be released to avoid memory leak.
        this.gridApi = null;
        this.gridColumnApi = null;
      }
      this.updateModalShow(newValue);
    }
  },
  computed: {
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    skillTitle() {
      return this.skillId && this.skillId.indexOf('SKILL_NEW') == -1? this.$t('skill.title_detail'): this.$t('skill.title_new');
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
    labelTitle() {
      return this.title? this.title : this.$t('skill.title_selector');
    },
    showDuplicateNameError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicate.name');
    },
    duplicateTitle() {
      return this.$t('skill.title_duplicate');
    },
    colorMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    }
  },
  methods: {
    onGridReady(params) {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      
      const self = this;
      const updateData = () => {
        params.api.setServerSideDatasource(new ServerSideDatasource(self));
      };

      updateData();
      
    },
    buildParams({ request: {sortModel, endRow, startRow} }) {
      const self = this;
    
      const params = {
        start: !self.exportData ? startRow : 0,
        limit: !self.exportData ? endRow - startRow + 1 : -1,
      };
      params.ksort = []
      params.order = []
      for(let i = 0, len = sortModel.length; i < len; i++) {
        params.ksort.push(sortModel[i].colId);
        params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr');
      }
      params.filter = this.searchFilter;
      return params;
    },
    modalOpen(isNew) {
      if(isNew) {
        this.skillId = `SKILL_NEW_${strRandom(5)}`;
      } else {
        this.skillId = this.selected[0];
      }
      this.skillShow = true;
      this.resetAlert();
    },
    modalSuccess(payload) {
      this.gridApi.refreshServerSide({ purge: true });
      this.resetAlert({ msg: payload.msg });
      this.scrollToTop();
    },
    rowDelete() {
      this.confirmDeleteShow = true;
    },
    async confirmDeleteOk(){ 
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('skill.progress.deleting');
      const selectedNodes = this.gridOptions.api.getSelectedNodes();
      const toDeleteIdNames = selectedNodes.map(node => { return { uuId: node.data.uuId, name: node.data.name != null? node.data.name : node.data.label } });
      const toDeleteIds = this.selected.map(i => { return { uuId: i } });
      let deletedIds = [];
      let alertState = alertStateEnum.SUCCESS;
      let alertMsg = this.$t(`skill.delete${toDeleteIds.length > 1? '_plural':''}`);
      let alertMsgDetailTitle = null;
      let alertMsgDetailList = [];

      await skillService.remove(toDeleteIds)
      .then(response => {
        if (response.status == 207) {
          alertState = alertStateEnum.WARNING;
          alertMsg = this.$t('skill.delete_partial');
          alertMsgDetailTitle = this.$t(`skill.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
          const feedbackList = response.data[response.data.jobCase];
          for (let i = 0, len = feedbackList.length; i < len; i++) {
            const feedback = feedbackList[i];
            const targetId = toDeleteIds[i].uuId;
            if (feedback.clue == 'OK') {
              deletedIds.push(targetId);
              continue;
            }
            const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
            alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
          }
        } else {
          deletedIds = toDeleteIds.map(i => i.uuId);
        }
      })
      .catch(e => {
        alertState = alertStateEnum.ERROR;
        alertMsg = this.$t(`skill.error.delete_failure${toDeleteIds.length > 1? '_plural' : ''}`);
        if (e.response) {
          const response = e.response;
          if (response.status == 422) {
            alertMsgDetailTitle = this.$t(`skill.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
            const feedbackList = response.data[response.data.jobCase];
            for (let i = 0, len = feedbackList.length; i < len; i++) {
              const feedback = feedbackList[i];
              if (feedback.clue == 'OK') {
                continue;
              }
              const targetId = toDeleteIds[i].uuId;
              const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
              alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
            }
          } else if (403 === response.status) {
            alertMsg = this.$t('error.authorize_action');
          }
          this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
          this.scrollToTop();
        }
      });

      if (alertState !== alertStateEnum.ERROR) {
        this.gridApi.refreshServerSide({ purge: true });
        this.$emit('delete', { ids: deletedIds });
      }

      const alertPayload = {
        msg: alertMsg,
        alertState: alertState
      }
      if (alertMsgDetailList.length > 0) {
        alertPayload.details = alertMsgDetailList;
        alertPayload.detailTitle = alertMsgDetailTitle;
      }
      this.inProgressShow = false;
      this.resetAlert(alertPayload);
    },
    httpAjaxError(e) {
      const response = e.response;
      let alertMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        alertMsg = this.$t('error.authorize_action');
      }
      this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.id}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    },
    updateSelected(newValue) {
      this.selected.splice(0, this.selected.length, ...newValue);
    },
    updateModalShow(newValue) {
      this.modalShow = newValue;
    },
    ok() {
      const details = this.gridApi.getSelectedNodes().map(i => { return {uuId: i.data.uuId, name: i.data.name, color: i.data.color} });
      this.$emit('ok', { ids: [...this.selected], details: details });
      this.$emit('input', [...this.selected]);
      
    },
    hidden() {
      this.selected.splice(0, this.selected.length);
      this.$emit('update:show', false);
      this.$emit('cancel');
    },
    openDetail(id){
      this.skillId = id;
      this.skillShow = true;
      this.resetAlert();
    },
    resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertMsg = msg;
      this.alertState = alertState;
      this.alertMsgDetails.title = detailTitle;
      const list = this.alertMsgDetails.list;
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details);
      } else {
        list.splice(0, list.length);
      }
    },
    applyFilter(pattern) {
      this.searchFilter = pattern;
      
      this.searchFilterTerm = this.settings[`skill_selector_search`] = this.searchFilter;
      this.updateViewProfile()
      
      if (this.gridApi) {
        this.gridApi.refreshServerSide({ purge: true });
      }
    },
    showDuplicateDialog() {
      this.resetAlert();
      const origName = this.gridApi.getRowNode(this.selected[0]).data.name;
      this.duplicateName = `${origName} (Copy)`;
      this.duplicateShow = true;
    },
    duplicateOk() {
      this.duplicateEntity();
    },
    duplicateEntity: debounce(function() {
      this.duplicateInProgress = true;
      if(!this.duplicateName || this.duplicateName.trim().length < 1) {
        this.errors.add({
          field: `duplicate.name`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('skill.field.name')])
        });
      }
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          skillService.clone(this.selected[0], { name: this.duplicateName })
          .then(() => {
            this.resetAlert({ msg: this.$t('skill.duplicate') });
            this.gridApi.refreshServerSide({ purge: true });
          })
          .catch(e => {
            let  alertMsg = this.$t('error.clone.failure', [this.$t('entityType.SKILL')]);
            if(e.response && e.response.status == 404 && e.response.data && e.response.data.jobClue != null) {
              const clue = e.response.data.jobClue.clue;
              if ('Unknown_holder' === clue) {
                alertMsg = this.$t('skill.error.duplicate_not_found');
              }
              this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR })
              this.scrollToTop();
            } else {
              this.httpAjaxError(e);
            }
          })
          .finally(() => {
            this.duplicateShow = false;
            this.errors.clear();
            //Make sure the dialog is closed before reenable duplicate button to avoid button spamming.
            this.$nextTick(() => {
              this.duplicateInProgress = false;
            });
          });
        } else {
          this.duplicateInProgress = false;
        }
      });
    }, 100),
    duplicateCancel() {
      this.duplicateShow = false;
      this.errors.clear();
    },
    updateViewProfile() {
      viewProfileService.update([this.settings], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    createViewProfile() {
      viewProfileService.create([this.settings],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.settings.uuId = data[0].uuId;
        this.newToProfile = true;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    async loadViewProfile() {
      const self = this;
      await this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {  
        const profileData = value;
        if (profileData.length === 0) {
          self.createViewProfile();
        }
        else {
          self.settings = profileData[0];
         
          if (typeof self.settings.skill_selector_list !== 'undefined') {
            self.loadColumnSettings(self, self.settings.skill_selector_list);
            self.coloring.none = self.settings.skill_selector_coloring ? self.settings.skill_selector_coloring.none : true;
            self.coloring.skill = self.settings.skill_selector_coloring ? self.settings.skill_selector_coloring.skill : false;
              
            this.searchFilterTerm = typeof this.settings[`skill_selector_search`] !== 'undefined' ? this.settings[`skill_selector_search`] : '';
            if (this.searchFilterTerm !== '') {
              this.applyFilter(this.searchFilterTerm);
            }
            
          } else {
            self.newToProfile = true;
          }
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadColumnSettings(data, columns) {
      // order the columns based upon the order in 'columns'
      let idx = 0;
      columns.forEach(function(col) {
        const index = data.columnDefs.findIndex((c) => c.colId === col.colId || c.field === col.colId);
        if (index !== -1) {
          data.columnDefs.splice(idx++, 0, data.columnDefs.splice(index, 1)[0]);
        }
      });
      
      for (const column of data.columnDefs) {
        const setting = columns.filter(c =>  c.colId === column.colId || c.colId === column.field);
        if (setting.length === 0) {
          column.hide = true;
        }
        else {
          column.hide = false;
          column.width = setting[0].width;
          column.sort = setting[0].sort;
          column.sortIndex = setting[0].sortIndex;
        }
      }
      
      if (data != null && data.gridOptions != null && data.gridOptions.api != null) {
        data.gridOptions.api.setColumnDefs([]);
        data.gridOptions.api.setColumnDefs(data.columnDefs);
      }
      if (this.profileColumnLoaded != true) {
        this.profileColumnLoaded = true;
      }
      return false;
    },
    async fileImport() {
      const existingData = await skillService.listNames({ start: 0, limit: -1 }).then((response) => {
        return response.data;
      });
      this.existingData = existingData;
      this.docImportShow = true;
    },
    processCellCallback(self) {
      return function(params) {
        const colId = params.column.colId
        if (colId.indexOf('uuId') !== -1) {
          return params.node.data.name;
        } else if (self.skillLevelNames.includes(colId)) {
          return params.value != null 
          && params.value[colId] != null
          && params.value[colId].data != null?
          params.value[colId].data : ''
        }
        return params.value;
      }
    },
    fileExport() {
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('dataview.exporting');
      this.exportData = true;
      
      let listener = () =>{
    
        const keys = this.gridColumnApi
          .getAllColumns()
          .map(column => column.getColId())
      
        const self = this;
        this.gridApi.exportDataAsExcel({ 
          fileName: 'Skills'
          , sheetName: 'Skills'
          , columnKeys: keys
          , rowHeight: 20
          , processCellCallback: self.processCellCallback(self)
        });
      
        this.exportData = false;
        this.inProgressShow = false;
        this.gridOptions.api.removeEventListener('modelUpdated', listener);
      };
      
      this.gridApi.refreshServerSide({purge: true});
      this.gridOptions.api.addEventListener('modelUpdated', listener);
    },
    async docImportOk({ items }) {
      this.docImportShow = false;
      this.inProgressShow = true;
      this.resetAlert();
      await this.addSkills(items);
      this.inProgressShow = false;
      this.gridApi.refreshServerSide({ purge: true });
    },
    async addSkills(items) {
      this.inProgressLabel = this.$t('skill.progress.importing', [0]);
      let percentage = 0;
      for (const item of items) {
        const data = {
          name: item.name,
          identifier: item.identifier,
          color: item.color
        }
        const skillLevels = []
        for (const prop of this.skillLevelNames) {
          let v = item[prop]
          if (v == null || isNaN(v)) {
            v = 5
          } else if (typeof v != 'number') {
            v = parseInt(v)
          }
          if (v < 1) {
            v = 1
          } else if (v > 365) {
            v = 365
          }
          skillLevels.push({ kind: prop, data: v })
        }
        data.skillLevels = skillLevels
        
        const result = await skillService.create([data])
        .then(response => {
          const feedbackList = response.data.feedbackList;
          if (Array.isArray(feedbackList) && 
                feedbackList.length > 0 && 
                feedbackList[0].uuId != null) {
            return feedbackList[0].uuId;
          }
        })
        .catch((e) => {
          this.httpAjaxError(e);
          return null;
        });
        
        if (result &&
          item.tag) {
          await addTags(result, item.tag.split(',').map(t => { return { name: t.trim() }}), skillLinkTagService);
        }
        
        percentage++;
        this.inProgressLabel = this.$t('skill.progress.importing', [parseFloat(percentage / items.length * 100).toFixed(0)]);
      
      }
    },
    docImportCancel() {
      this.docImportShow = false;
    },
    onColoringOver() {
      this.$refs.coloring.visible = true;
    },
    onColoringLeave() {
      this.$refs.coloring.visible = false;
    },
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }
      if ('ontouchstart' in window) {
          return true;
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    },
    onColorChange(val) {
      for (const key of Object.keys(this.coloring)) {
        this.coloring[key] = false;
      }
      this.coloring[val] = true;
      this.settings['skill_selector_coloring'] = this.coloring;
      this.updateViewProfile();
      this.gridApi.redrawRows();
    },
    getRowColor(data) {
      if (data &&
        data.color &&
        this.coloring.skill) {
        return data.color;
      }
    },
    prepareNoRowsMessage() {
      if (this.noRowsMessage != null) {
        return this.noRowsMessage
      }
      return this.$t(`entity_selector.skill_grid_no_data`)
    },
    showNoRowLabel(msg=null) {
      this.noRowsMessage = msg
      let api = this.gridOptions != null? this.gridOptions.api : null
      if (api != null) {
        this.gridOptions.api.hideOverlay()
        setTimeout(() => {
          api.showNoRowsOverlay()
        })
      }
    }, 
    async init() {
      const self = this;
      await this.$store.dispatch('data/enumList')
      .then(enumResp => {
        if (enumResp != null) {
          if (enumResp.jobCase != null && enumResp[enumResp.jobCase] != null) {
            const propertyList = enumResp[enumResp.jobCase]
            const obj = propertyList.SkillLevelEnum
            const codes = Object.keys(obj)
            const list = []
            for (const c of codes) {
              if (obj[c] < 0) {
                continue
              }
              list.push({ value: c, text: c, num: obj[c] })
            }
            this.skillLevelOpts.splice(0, this.skillLevelOpts.length, ...list)
            this.skillLevelNames.splice(0, this.skillLevelNames.length, ...list.map(i => i.value))
          }
        }
      })
      .catch(e => {
        console.error(e) //eslint-disable-line no-console
      })

      const properties = [
        { value: 'color', text: this.$t('field.color') }
        , { value: 'identifier', text: this.$t('field.identifier') }
        , { value: 'name', text: this.$t('skill.field.name') }
        , { value: 'tag', text: this.$t('field.tag') }
      ]
      for (const level of this.skillLevelOpts) {
        if (level.num < 0) {
          continue
        }
        properties.push({ value: level.value, text: level.value })
      }
      this.docImportProperties.splice(0, this.docImportProperties.length, ...properties)
    
      const colDefs = [
        {
          headerName: this.$t('skill.field.name'),
          field: 'uuId',
          cellRenderer: 'detailLinkCellRenderer',
          checkboxSelection: true,
          lockVisible: true,
          pinned: 'left',
          minWidth: 200,
          hide: false,
          sort: 'asc',
          cellStyle: params => {
              if (params.data &&
                  params.data.color &&
                  self.coloring.skill) {
                  return { background: params.node.data.color, color: invertColor(params.node.data.color, true) };
              }
          }
        },
        {
          headerName: this.$t('skill.field.headCount'),
          field: 'staffCount',
          hide: false,
          minWidth: 100,
          sortable: false
        },
      ]

      for (const level of this.skillLevelOpts) {
        colDefs.push(...[
          {
            headerName: level.text,
            colId: level.value,
            field: 'levelGroup',
            cellRenderer: 'skillLevelCellRenderer',
            minWidth: 150,
            sortable: false
          },
          {
            headerName: this.$t('skill.field.level_count', [level.value]),
            field: `${level.value}Count`,
            cellRenderer: 'genericCellRenderer',
            minWidth: 150,
            sortable: false
          }
        ])
      }
        
      colDefs.push(...[
        {
          headerName: this.$t('field.tag'),
          field: 'tag',
          minWidth: 100
        },
        {
          headerName: this.$t('field.color'),
          field: 'color',
          cellRenderer: 'colorCellRenderer'
        },
        {
          headerName: this.$t('field.identifier_full'),
          field: 'identifier',
          minWidth: 100
        }
      ]);

      const linkedEntities = [
        { selector: 'SKILL.TAG', field: 'tag', properties: ['name'] }
      ]
      await this.$store.dispatch('data/schemaAPI', {type: 'api', opts: 'brief' })
      
      filterOutViewDenyProperties(colDefs, 'SKILL', linkedEntities);
      colDefs.sort(columnDefSortFunc);
      this.columnDefs = colDefs;

      await this.loadViewProfile()
    }
  }
}


</script>